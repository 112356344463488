<template>
    <div class="p-4">

        <div>

            <div class="d-flex align-items-end mb-3">
                <h4 class="flex-fill">Applications with expiring documents</h4>
                <div class="flex-wrap d-flex flex-column align-items-end">
                    <b-form-group
                            id="expiring-date"
                            label="Show within this date range"
                    >
                        <date-range-picker
                                class="d-block"
                                ref="expiring-date-picker"
                                opens="left"
                                :show-dropdowns="true"
                                :linked-calendars="false"
                                v-model="dateRange"
                                :dateRange="dateRange != null ? dateRange : {}"
                                :locale-data="localeData"
                                :ranges="false"
                                date-util="moment"
                        ></date-range-picker>
                    </b-form-group>
                    <b-button id="expiring-csv" @click="downloadExcel" size="sm" variant="success">
                        <b-icon-cloud-download></b-icon-cloud-download>
                        Download to CSV
                    </b-button>
                </div>

            </div>

            <paginated-result-loader :hide-batch-actions="true" endpoint="applications" :show-expiry="true"
                                     cell-type="applications"
                                     :filters="filtersOutput"></paginated-result-loader>
        </div>
    </div>
</template>
<script>
    import PaginatedResultLoader from "../../components/PaginatedResultLoader";
    import {getResource} from "../../modules/api/methods";
    import {applicationsExcel, applicationStats, userSingleUse} from "../../modules/api/endpoints";
    import DateRangePicker from "vue2-daterange-picker/src/components/DateRangePicker";
    import moment from 'moment'

    export default {
        components: {DateRangePicker, PaginatedResultLoader},
        name: "DashboardHome",
        data: function () {
            return {
                isLoading: false,
                dateRange: {
                    startDate: this.startDate(),
                    endDate: this.endDate()
                },
                localeData: {
                    direction: 'ltr',
                    format: 'DD/MM/YYYY',
                    separator: ' to ',
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'W',
                    customRangeLabel: 'Custom Range',
                    daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    firstDay: 0
                }
            }
        },
        created() {
            this.loadStats();
        },
        computed: {
            filtersOutput() {
                return [{api_key: 'expiry_at', 'value': this.dateRange}];
            }
        },
        methods: {
            serialize(obj, prefix) {
                var str = [],
                    p;
                for (p in obj) {
                    if (obj.hasOwnProperty(p)) {
                        var k = prefix ? prefix + "[" + p + "]" : p,
                            v = obj[p];
                        console.log("KEY IS", k);
                        str.push((v !== null && typeof v === "object") ?
                            this.serialize(v, k) :
                            encodeURIComponent(k) + "=" + encodeURIComponent(v));
                    }
                }
                var final = str.join("&");
                console.log("FINAL PARAMS", final);
                return final;
            },
            downloadExcel() {

                this.isLoading = true;
                getResource(userSingleUse(this.$store.getters.isAdmin)).then((resp) => {
                    var query = "token=" + resp.data.success.token + "&expiring_documents=1&";
                    if (new moment(this.dateRange.startDate).isValid()) {
                        query = query + "&" + encodeURIComponent('expiry_at[startDate]') + "=" + new moment(this.dateRange.startDate).format("YYYY-MM-DD");
                    }
                    if (new moment(this.dateRange.endDate).isValid()) {
                        query = query + "&" + encodeURIComponent('expiry_at[endDate]') + "=" + new moment(this.dateRange.endDate).format("YYYY-MM-DD");
                    }
                    window.open(applicationsExcel(query), '_blank');

                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            startDate() {
                var d = new Date();
                d.setMonth(d.getMonth() - 12);
                return d;
            },
            endDate() {
                var d = new Date();
                d.setMonth(d.getMonth() + 12);
                return d;
            },
            loadStats() {
                this.isLoading = true;
                getResource(applicationStats).then((resp) => {
                    console.log(resp);
                    this.stats = resp.data.success;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    }
</script>
